import '~/components/shared/tracking/globalTracking';
import '~/components/shared/overflowExpandableController';
import '~/components/shared/globalFooter';
import '~/components/shared/navigation';

const currentPage = document.body.id;
const loadConsumer = [
    'b2c-blog-show',
    'b2c-health_library-show',
    'b2c-blog-by_category',
    'b2c-blog-search',
];

if (currentPage === 'b2c-blog-index' || currentPage === 'b2c-blog-thank_you') {
    import('~/components/b2c/blog/exploreByCategoryController');
} else if (currentPage === 'b2c-blog-show') {
    import('~/components/consumer/modules/blog-show');
    import('~/components/b2c/blog/blogCollapsibleController');
    import('~/components/b2c/blog/blogPageBannerController');
    import('~/components/b2c/blog/reviewedInfoController');
} else if (currentPage === 'b2c-health_library-show') {
    import('~/components/b2c/blog/blogExpandableContentController');
} else if (currentPage === 'b2c-pages-press') {
    import('~/components/b2c/press');
} else if (currentPage === 'b2c-pages-app_download') {
    import('~/components/b2c/appDownload');
} else if (currentPage === 'b2c-groups-show') {
    import('~/components/b2c/groups/groups');
} else if (currentPage === 'b2c-marketplace-root-service_line_landing_pages-show') {
    import('~/components/b2c/directory/serviceLineLandingPage');
} else if (currentPage === 'b2b-reviews-index') {
    import('~/components/b2b/reviewsLandingPage');
} else if (currentPage === 'b2c-marketplace-root-marketplace_landing_pages-show') {
    import('~/components/b2c/blog/reviewedInfoController');
    import('~/components/b2c/blog/blogCollapsibleController');
    import('~/components/b2c/marketplace_landing_page');
} else if (currentPage.includes('html_sitemap')) {
    import('~/components/b2c/alphabetSelector');
    import('~/components/b2c/locationsNearYouSlider');
    import("~/components/b2c/find_care_form");
    import('~/components/shared/controllers/autocompleteController');
}

if (loadConsumer.includes(currentPage)) {
    import('~/components/b2c/blogQuizlet');
    import('~/components/consumer/modules/consumer');
}

if (currentPage === 'b2c-marketplace-city_landing_pages-san_antonio_landing_page') {
    import('~/components/b2c/cityLandingPage');
    import('~/components/b2c/directory/bookableTimes');
    import('~/components/b2c/directory/nonApiBookableTimes');
    import('~/components/shared/controllers/expansionController');
    import('~/components/shared/controllers/autocompleteController');
    import('~/components/shared/controllers/slideOutController');
}

if (currentPage === 'b2c-groups-index') {
    import('~/components/b2c/bestPage');
    import('~/components/shared/controllers/expansionController');
    import('~/components/shared/controllers/showMoreController');
}


